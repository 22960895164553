import { useRef } from 'react';

import { Drop, TooltipHover, Action, BottomSheet, Cell, CellText } from '@hh.ru/magritte-ui';
import { EyeOutlinedSize24, EyeCrossedOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import Menu, { MenuPlacement, MenuItem } from 'bloko/blocks/drop/Menu';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'lux/components/translation';
import useMagritte from 'lux/hooks/useMagritte';

import { BlacklistStatus, useBlacklistMenu } from 'lux/components/VacancyView/useBlacklistMenu';

const TrlKeys = {
    title: 'pageControls.BlacklistAdd',
    addVacancy: 'vacancy.Blacklist.AddVacancy',
    addEmployer: 'vacancy.Blacklist.AddEmployer',
    [BlacklistStatus.Empty]: 'searchvacancymap.close',
    [BlacklistStatus.BlacklistVacancy]: 'pageControls.BlacklistRemoveVacancy',
    [BlacklistStatus.BlacklistEmployer]: 'pageControls.BlacklistRemoveEmployer',
};

export const getTitle = (
    status: BlacklistStatus,
    trls: {
        blacklistAdd: string;
        removeVacancy: string;
        removeEmployer: string;
    }
): string => {
    if (status === BlacklistStatus.BlacklistVacancy) {
        return trls.removeVacancy;
    }
    if (status === BlacklistStatus.BlacklistEmployer) {
        return trls.removeEmployer;
    }
    return trls.blacklistAdd;
};

interface BlacklistMenuProps {
    render: (params: { status: BlacklistStatus; isLoading: boolean; onClick: () => void }) => JSX.Element;
    vacancyId?: number;
    employerId?: number;
    checkInitialStatus?: boolean;
    isAnonymousVacancy: boolean;
}

const BlacklistMenu: TranslatedComponent<BlacklistMenuProps> = ({
    trls,
    render,
    checkInitialStatus,
    vacancyId,
    employerId,
    isAnonymousVacancy,
}) => {
    const isMagritte = useMagritte();
    const eyeIconRef = useRef(null);

    const { status, isLoading, isMenuVisible, setIsMenuVisible, makeAction, addVacancy, addEmployer, onButtonClick } =
        useBlacklistMenu({
            vacancyId,
            checkInitialStatus,
            employerId,
        });

    if (isMagritte) {
        const blacklistOptionsElement = (
            <>
                <Cell
                    vertPadding
                    horPadding
                    roundedBorder
                    onClick={() => makeAction(addVacancy)}
                    data-qa="vacancy__blacklist-menu-add-vacancy"
                >
                    <CellText>{trls[TrlKeys.addVacancy]}</CellText>
                </Cell>

                {!isAnonymousVacancy && (
                    <Cell
                        vertPadding
                        horPadding
                        roundedBorder
                        onClick={() => makeAction(addEmployer)}
                        data-qa="vacancy__blacklist-menu-add-employer"
                    >
                        <CellText>{trls[TrlKeys.addEmployer]}</CellText>
                    </Cell>
                )}
            </>
        );

        return (
            <>
                <Action
                    onClick={onButtonClick}
                    aria-label={trls[TrlKeys[status]]}
                    data-qa={status === BlacklistStatus.Empty ? 'vacancy__blacklist-show-add' : undefined}
                    mode="secondary"
                    style="neutral"
                    icon={status === BlacklistStatus.Empty ? EyeCrossedOutlinedSize24 : EyeOutlinedSize24}
                    ref={eyeIconRef}
                />

                <TooltipHover activatorRef={eyeIconRef} direction="top" alignment="center">
                    {trls[TrlKeys[status]]}
                </TooltipHover>

                <Drop
                    visible={isMenuVisible}
                    direction="bottom"
                    alignment="right"
                    activatorRef={eyeIconRef}
                    maxWidth={400}
                    onClose={onButtonClick}
                    space={300}
                >
                    {blacklistOptionsElement}
                </Drop>

                <BottomSheet visible={isMenuVisible} onClose={onButtonClick}>
                    {blacklistOptionsElement}
                </BottomSheet>
            </>
        );
    }

    return (
        <Menu
            show={isMenuVisible}
            onClose={() => setIsMenuVisible(false)}
            placement={MenuPlacement.BottomStart}
            render={() => {
                return (
                    <>
                        <MenuItem onClick={() => makeAction(addVacancy)} data-qa="vacancy__blacklist-menu-add-vacancy">
                            {trls[TrlKeys.addVacancy]}
                        </MenuItem>
                        {!isAnonymousVacancy && (
                            <MenuItem
                                onClick={() => makeAction(addEmployer)}
                                data-qa="vacancy__blacklist-menu-add-employer"
                            >
                                {trls[TrlKeys.addEmployer]}
                            </MenuItem>
                        )}
                    </>
                );
            }}
        >
            {render({
                status,
                isLoading,
                onClick: onButtonClick,
            })}
        </Menu>
    );
};
export default translation(BlacklistMenu);
