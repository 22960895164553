import { useContext } from 'react';

import Analytics from '@hh.ru/analytics-js';
import { ChatikContext } from '@hh.ru/chatik-integration';
import { Avatar, Button as MagritteButton, Cell, CellText, VSpacing } from '@hh.ru/magritte-ui';
import { BubbleSquareOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import Button, { ButtonKind } from 'bloko/blocks/button';
import Gap from 'bloko/blocks/gap';
import Loading, { LoadingScale, LoadingColor } from 'bloko/blocks/loading';
import Text, { TextImportance, TextSize } from 'bloko/blocks/text';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import VacancyResponseLink, { VacancyResponsePopupResponse } from 'lux/components/VacancyResponseLink';
import translation from 'lux/components/translation';
import { UserType } from 'lux/models/userType';
import { useSelector } from 'lux/modules/useSelector';

import styles from './contact-chat.less';

interface ContactChatProps {
    vacancyId: number;
    chatButtonInfo: VacancyResponsePopupResponse;
    employerId: number;
    isMagritteExp?: boolean;
}

const TrlKeys = {
    title: 'vacancy.contacts.chat.title',
    description: 'vacancy.contacts.chat.description',
    disclaimer: 'vacancy.contacts.chat.disclaimer',
    button: 'vacancy.contacts.chat.button',
};

const ContactChat: TranslatedComponent<ContactChatProps> = ({
    chatButtonInfo,
    vacancyId,
    employerId,
    isMagritteExp,
    trls,
}) => {
    const openChatik = useContext(ChatikContext)?.openChatik;
    const responseStatus = useSelector((state) => state.applicantVacancyResponseStatuses[vacancyId]);
    const isApplicant = useSelector(({ userType }) => UserType.Applicant === userType);

    if (!isApplicant) {
        return null;
    }

    if (isMagritteExp) {
        const info = (
            <Cell
                avatar={
                    <Avatar
                        aria-label={trls[TrlKeys.title]}
                        mode="icon"
                        size={48}
                        style="secondary"
                        icon={<BubbleSquareOutlinedSize24 initial="primary" />}
                    />
                }
            >
                <CellText type="title">{trls[TrlKeys.title]}</CellText>
                <CellText type="subtitle">{trls[TrlKeys.description]}</CellText>
            </Cell>
        );

        if (chatButtonInfo.type === 'alreadyApplied') {
            return (
                <div>
                    {info}
                    <VSpacing default={16} />
                    <div className={styles.chatButton}>
                        <MagritteButton
                            mode="primary"
                            style="accent"
                            onClick={() => {
                                Analytics.sendHHEventButtonClick('contactChat', { withResponse: false });
                                openChatik?.({ chatId: chatButtonInfo.chatId, view: 'widget' });
                            }}
                        >
                            {trls[TrlKeys.button]}
                        </MagritteButton>
                    </div>
                </div>
            );
        }

        return (
            <div>
                {info}
                <VSpacing default={16} />
                <VacancyResponseLink
                    vacancyId={vacancyId}
                    employerId={employerId}
                    enableRelocationWarning
                    openChatikAfterResponse
                    render={(onClick) => (
                        <div className={styles.chatButton}>
                            <MagritteButton
                                onClick={(event) => {
                                    Analytics.sendHHEventButtonClick('contactChat', { withResponse: true });
                                    onClick(event);
                                }}
                                mode="primary"
                                style="accent"
                                loading={!!responseStatus?.isFetching}
                                disabled={!!responseStatus?.isFetching}
                                data-qa="vacancy-serp__vacancy_response"
                            >
                                {trls[TrlKeys.button]}
                            </MagritteButton>
                        </div>
                    )}
                />
            </div>
        );
    }

    const info = (
        <div className="vacancy-contacts__chat-info">
            <Text size={TextSize.Small} importance={TextImportance.Secondary}>
                {trls[TrlKeys.disclaimer]}
            </Text>
        </div>
    );

    if (chatButtonInfo.type === 'alreadyApplied') {
        return (
            <Gap top>
                {info}
                <Button
                    kind={ButtonKind.Primary}
                    onClick={() => {
                        Analytics.sendHHEventButtonClick('contactChat', { withResponse: false });
                        openChatik?.({ chatId: chatButtonInfo.chatId, view: 'widget' });
                    }}
                >
                    {trls[TrlKeys.button]}
                </Button>
            </Gap>
        );
    }

    return (
        <Gap top>
            {info}
            <VacancyResponseLink
                vacancyId={vacancyId}
                employerId={employerId}
                enableRelocationWarning
                openChatikAfterResponse
                render={(onClick) => (
                    <Button
                        onClick={(event) => {
                            Analytics.sendHHEventButtonClick('contactChat', { withResponse: true });
                            onClick(event);
                        }}
                        kind={ButtonKind.Primary}
                        loading={
                            responseStatus?.isFetching && (
                                <Loading initial={LoadingColor.White} scale={LoadingScale.Small} />
                            )
                        }
                        disabled={!!responseStatus?.isFetching}
                        data-qa="vacancy-serp__vacancy_response"
                    >
                        {trls[TrlKeys.button]}
                    </Button>
                )}
            />
        </Gap>
    );
};

export default translation(ContactChat);
