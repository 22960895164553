import { ReactNode, useState } from 'react';

import applicantActivityIndexElementShown from '@hh.ru/analytics-js-events/build/xhh/applicant/experiments/PORTFOLIO-19950/applicant_activity_index_element_shown';
import Button from 'bloko/blocks/button';
import HoverTip, { TipPlacement, TipProvider } from 'bloko/blocks/drop/Tip/HoverTip';
import { H2Section } from 'bloko/blocks/header';
import { InfoScaleMedium, IconColor } from 'bloko/blocks/icon';
import Link, { LinkAppearance, LinkKind } from 'bloko/blocks/link';
import Modal from 'bloko/blocks/modal';
import Text, { TextSize } from 'bloko/blocks/text';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import ElementShownAnchor from 'lux/components/ElementShownAnchor';
import translation from 'lux/components/translation';
import { useSelector } from 'lux/modules/useSelector';

import ActivityMeterBar from 'lux/components/Applicant/ActivityMeter/Bar';

import styles from './meter.styles.less';

interface ActivityMeterProps {
    showDescription?: boolean;
    largeText?: boolean;
    showChange?: boolean;
    isBlue?: boolean;
    isAnimatable?: boolean;
    spacingBefore?: ReactNode;
    spacingAfter?: ReactNode;
}

const TrlKeys = {
    title: 'applicantActivityMeter.title',
    titleShort: 'applicantActivityMeter.title.short',
    description1: 'applicantActivityMeter.description.part1',
    description2: 'applicantActivityMeter.description.part2',
    descriptionLink: 'applicantActivityMeter.description.link',
    close: 'applicantActivityMeter.close',
};

const ActivityMeter: TranslatedComponent<ActivityMeterProps> = ({
    showDescription,
    largeText,
    showChange = true,
    isBlue,
    isAnimatable,
    spacingBefore,
    spacingAfter,
    trls,
}) => {
    const activity = useSelector((state) => state.applicantActivity);
    const [isBottomSheetVisible, setIsBottomSheetVisible] = useState(false);

    const renderTooltip = () => {
        return (
            <>
                <p>{trls[TrlKeys.description1]}</p>
                <VSpacing base={4} />
                <p>{trls[TrlKeys.description2]}</p>
            </>
        );
    };

    if (!activity) {
        return null;
    }

    return (
        <>
            {spacingBefore}
            <ElementShownAnchor
                fn={applicantActivityIndexElementShown}
                type={showDescription ? 'SEARCH' : 'RESPONSE'}
                index={activity.userActivityScore}
            >
                <div className={styles.header}>
                    <Text size={largeText ? TextSize.Large : undefined} strong>
                        {trls[TrlKeys.title]}
                        {showDescription && (
                            <span className={styles.infoIcon}>
                                <TipProvider>
                                    <HoverTip placement={TipPlacement.Bottom} render={renderTooltip}>
                                        <InfoScaleMedium initial={IconColor.Gray50} />
                                    </HoverTip>
                                </TipProvider>
                            </span>
                        )}
                    </Text>
                    <div className={styles.value}>
                        <Text size={largeText ? TextSize.Large : undefined} strong>
                            {activity.userActivityScore}%
                            {showChange && !!activity.userActivityScoreChange && (
                                <span className={styles.valueChange}>(+{activity.userActivityScoreChange}%)</span>
                            )}
                        </Text>
                    </div>
                </div>
                <VSpacing base={3} />
                <ActivityMeterBar
                    currentValue={activity.userActivityScore}
                    change={activity.userActivityScoreChange}
                    isBlue={isBlue}
                    isAnimatable={isAnimatable}
                />
                {showDescription && (
                    <>
                        <div className={styles.infoLink}>
                            <VSpacing base={2} />
                            <Link
                                appearance={LinkAppearance.Pseudo}
                                kind={LinkKind.Tertiary}
                                onClick={() => setIsBottomSheetVisible(true)}
                            >
                                {trls[TrlKeys.descriptionLink]}
                            </Link>
                        </div>
                        <Modal
                            useBottomSheet
                            visible={isBottomSheetVisible}
                            onClose={() => setIsBottomSheetVisible(false)}
                        >
                            <div className={styles.bottomsheetContent}>
                                <VSpacing base={4} />
                                <div className={styles.bottomsheetIcon} />
                                <VSpacing base={6} />
                                <H2Section Element="span">{trls[TrlKeys.titleShort]}</H2Section>
                                <VSpacing base={6} />
                                <p>{trls[TrlKeys.description1]}</p>
                                <VSpacing base={4} />
                                <p>{trls[TrlKeys.description2]}</p>
                                <VSpacing base={6} />
                                <Button stretched onClick={() => setIsBottomSheetVisible(false)}>
                                    {trls[TrlKeys.close]}
                                </Button>
                            </div>
                        </Modal>
                    </>
                )}
            </ElementShownAnchor>
            {spacingAfter}
        </>
    );
};

export default translation(ActivityMeter);
