export interface ContactPhone {
    city: string;
    country: string;
    number: string;
    comment?: string;
}

export interface ContactInfo {
    email?: string;
    fio: string;
    phones?: {
        phones: ContactPhone[];
    };
    callTrackingEnabled: true;
}

export interface VacancyViewInsider {
    id: number;
    ['@isVisible']?: boolean;
    isVisible?: boolean;
}

export interface VacancyViewStatus {
    waiting?: boolean;
    needFix?: boolean;
    archived?: boolean;
    disabled?: boolean;
}

export interface VacancyViewMetroStation {
    '@cityId': number;
    '@id': number;
    line: {
        color: string;
    };
    name: string;
}
export interface VacancyAddress {
    city?: string;
    street?: string;
    building?: string;
    rawAddress?: string;
    mapData?: {
        points: {
            center: {
                lat: number;
                lng: number;
                zoom: number;
            };
            marker: {
                lat: number;
                lng: number;
            };
        };
    };
    metroStations: {
        metro: VacancyViewMetroStation[];
    };
    displayName?: string;
}

export enum BadgeType {
    EmployerHHRating = 'employer-hh-rating',
    HrBrand = 'hrbrand',
}

export interface Badge {
    type: BadgeType;
    url: string;
    description: string | string[];
    position?: string;
    year?: string | null;
    dataQASpecific?: string[];
}

interface HrBrandData {
    '@url': string;
    '@year'?: string;
}

interface HrBrandNominees {
    nominees: HrBrandData;
}

interface HrBrandWinners {
    winners: HrBrandData;
}

export type HrBrand = HrBrandNominees & HrBrandWinners;

export enum EmploymentType {
    Probation = 'PROBATION',
    Full = 'FULL',
    Part = 'PART',
    Volunteer = 'VOLUNTEER',
    Project = 'PROJECT',
}

export enum PartTimeOption {
    Shifts = 'shifts',
    Weekends = 'weekends',
    Evenings = 'evenings',
}

export interface VacancyHistory {
    comment: string;
    time: string;
    type: string;
    user: string;
}

export interface VacancyMailing {
    count: number;
    date: number;
}

export interface SystemInfo {
    publicationTime: string;
    expirationTime: string;
    creationTime: string;
    history: VacancyHistory[];
    mailings: VacancyMailing[];
}
