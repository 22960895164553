import { useState, FC, useEffect, PropsWithChildren } from 'react';
import classnames from 'classnames';

import styles from './bar.styles.less';

interface ActivityMeterBarProps {
    currentValue: number;
    change?: number;
    isBlue?: boolean;
    isAnimatable?: boolean;
}

const ActivityMeterBar: FC<ActivityMeterBarProps & PropsWithChildren> = ({
    currentValue,
    change,
    isBlue,
    isAnimatable,
}) => {
    const [value, setValue] = useState(change && isAnimatable ? currentValue - change : currentValue);

    useEffect(() => {
        const timeoutId = setTimeout(
            () => {
                setValue(currentValue);
            },
            isAnimatable ? 800 : 0
        );
        return () => {
            clearTimeout(timeoutId);
        };
    }, [currentValue, isAnimatable]);

    return (
        <div
            className={classnames(styles.bar, {
                [styles.barBlue]: isBlue,
            })}
        >
            <div
                className={classnames(styles.barProgress, {
                    [styles.barProgressAnimatable]: isAnimatable,
                })}
                style={{ width: `${value}%` }}
            />
        </div>
    );
};

export default ActivityMeterBar;
