import { ReactNode } from 'react';

import { NextFunction } from 'lux/components/Markup/makrupTypes';
import wrapByRegExp from 'lux/components/Markup/wrapByRegExp';

const regExp = /([A-ZА-ЯЁÀ-ÿ0-9+_-]+@[A-ZА-ЯЁÀ-ÿ0-9]+?\.[A-ZА-ЯЁÀ-ÿ]{2,6})/gim;

const wrap = (found: RegExpExecArray) => (
    <a key={found.index} href={`mailto:${found[1]}`}>
        {found[1]}
    </a>
);

export default (next: NextFunction) =>
    (string: string): ReactNode => {
        return wrapByRegExp(string, regExp, wrap, next);
    };
