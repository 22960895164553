import { useState } from 'react';

import {
    BottomSheet,
    BottomSheetFooter,
    Button as MagritteButton,
    Modal,
    NavigationBar,
    Text,
    UncontrolledTextArea,
    useBreakpoint,
    VSpacingContainer,
    VSpacing as MagritteVSpacing,
} from '@hh.ru/magritte-ui';
import { CrossOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import Button, { ButtonKind, ButtonType } from 'bloko/blocks/button';
import { FormError } from 'bloko/blocks/form';
import { H3Section } from 'bloko/blocks/header';
import Loading, { LoadingScale, LoadingColor } from 'bloko/blocks/loading';
import Textarea from 'bloko/blocks/textarea';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import handleSubmit, { ErrorType } from 'Modules/Applicant/CoverLetterForResponse';
import Form from 'lux/components/Form';
import translation from 'lux/components/translation';
import { useSelector } from 'lux/modules/useSelector';

import styles from './response-cover-letter.less';

const DUMMY_VALIDATE = () => undefined;

const TrlKeys = {
    headLetter: 'vacancy.response.letter.informer.headLetter',
    headLetterMobile: 'vacancy.response.letter.informer.headLetter.mobile',
    placeholder: 'vacancy.response.letter.informer.placeholder',
    placeholderShort: 'vacancy.response.letter.informer.placeholder.mobile',
    textAreaPlaceholder: 'vacancy.response.letter.informer.textarea.placeholder',
    submit: 'vacancy.response.letter.informer.submit',
    close: 'vacancy.response.letter.informer.close',
    errors: {
        [ErrorType.LetterRequired]: 'vacancy.response.popup.letter.error',
        [ErrorType.Unknown]: 'vacancy.response.popup.unknown.error',
        [ErrorType.NotEditable]: 'vacancy.response.popup.letter.viewed',
        [ErrorType.SpamDetected]: 'vacancy.response.popup.spamDetected',
        [ErrorType.ResourcePolicyViolation]: 'vacancy.response.popup.resourcePolicyViolation',
        [ErrorType.InappropriateLanguage]: 'vacancy.response.popup.inappropriateLanguage',
        [ErrorType.TooLongLetter]: 'vacancy.response.popup.tooLongLetter',
    },
};

interface CoverLetterFormProps {
    letterMaxLength: number;
    topicId: number;
    setSuccessSend: (v: boolean) => void;
    submitButtonStretched?: boolean;
    initialText?: string;
    isMagritteExp?: boolean;
    isMagritteOnVacancyResponseExp?: boolean;
    onCloseForm?: () => void;
    showLetterForm?: boolean;
}

const CoverLetterForm: TranslatedComponent<CoverLetterFormProps> = ({
    trls,
    letterMaxLength,
    topicId,
    setSuccessSend,
    submitButtonStretched,
    initialText,
    isMagritteExp,
    isMagritteOnVacancyResponseExp,
    onCloseForm,
    showLetterForm,
}) => {
    const isMapVacancySearch = useSelector((state) => state.isMapVacancySearch);

    const [isLoading, setLoading] = useState(false);
    const [errorCode, setErrorCode] = useState(null as ErrorType | null);

    const { isMobile } = useBreakpoint();

    if (isMagritteExp) {
        const form = (
            <Form
                id="cover-letter"
                className={styles.letterForm}
                action="/applicant/vacancy_response/edit_ajax"
                onSubmit={(event) => handleSubmit({ event, setLoading, setErrorCode, setSuccessSend, onCloseForm })}
            >
                <UncontrolledTextArea
                    name="text"
                    maxLength={letterMaxLength}
                    minLength={10}
                    placeholder={trls[TrlKeys.textAreaPlaceholder]}
                    defaultValue={initialText}
                    rows={6}
                    errorMessage={trls[TrlKeys.errors[errorCode || ErrorType.Unknown]]}
                    invalid={!!errorCode}
                    onValidate={DUMMY_VALIDATE}
                    layout="fill-both"
                />
                <input type="hidden" name="topicId" value={topicId} />
            </Form>
        );

        const button = (
            <MagritteButton
                size={isMobile ? 'large' : 'small'}
                mode="primary"
                style="accent"
                loading={isLoading}
                type={ButtonType.Submit}
                disabled={isLoading}
                stretched={isMobile}
                data-qa="vacancy-response-letter-submit"
                form="cover-letter"
            >
                {trls[TrlKeys.submit]}
            </MagritteButton>
        );

        if (isMobile && onCloseForm) {
            return (
                <BottomSheet
                    height="full-screen"
                    visible={!!showLetterForm}
                    onClose={onCloseForm}
                    header={
                        <NavigationBar
                            title={trls[TrlKeys.headLetterMobile]}
                            right={{ icon: CrossOutlinedSize24, onClick: onCloseForm }}
                        />
                    }
                    footer={<BottomSheetFooter>{button}</BottomSheetFooter>}
                >
                    {form}
                </BottomSheet>
            );
        }

        if (isMapVacancySearch) {
            return (
                <Modal
                    visible={!!showLetterForm}
                    onClose={onCloseForm}
                    title={trls[TrlKeys.headLetter]}
                    titleSize="medium"
                    leftButtons={
                        <MagritteButton mode="secondary" style="accent" onClick={onCloseForm}>
                            {trls[TrlKeys.close]}
                        </MagritteButton>
                    }
                    rightButtons={button}
                >
                    {form}
                </Modal>
            );
        }

        if (!showLetterForm) {
            return null;
        }

        return (
            <VSpacingContainer default={8}>
                <Text typography="subtitle-2-semibold">{trls[TrlKeys.headLetter]}</Text>
                {form}
                <div>{button}</div>
            </VSpacingContainer>
        );
    }

    if (isMagritteOnVacancyResponseExp) {
        return (
            <Form
                id="cover-letter"
                action="/applicant/vacancy_response/edit_ajax"
                onSubmit={(event) => handleSubmit({ event, setLoading, setErrorCode, setSuccessSend, onCloseForm })}
            >
                <UncontrolledTextArea
                    name="text"
                    maxLength={letterMaxLength}
                    minLength={10}
                    placeholder={trls[TrlKeys.placeholderShort]}
                    defaultValue={initialText}
                    rows={3}
                    errorMessage={trls[TrlKeys.errors[errorCode || ErrorType.Unknown]]}
                    invalid={!!errorCode}
                    onValidate={DUMMY_VALIDATE}
                    layout="fill-both"
                />
                <input type="hidden" name="topicId" value={topicId} />
                <MagritteVSpacing default={24} />
                <MagritteButton
                    mode="primary"
                    size="small"
                    style="accent"
                    loading={isLoading}
                    type={ButtonType.Submit}
                    disabled={isLoading}
                    stretched
                    data-qa="vacancy-response-letter-submit"
                    form="cover-letter"
                >
                    {trls[TrlKeys.submit]}
                </MagritteButton>
            </Form>
        );
    }

    return (
        <>
            <H3Section>{trls[TrlKeys.headLetter]}</H3Section>
            <VSpacing base={4} />
            <Form
                action="/applicant/vacancy_response/edit_ajax"
                onSubmit={(event) => handleSubmit({ event, setLoading, setErrorCode, setSuccessSend })}
            >
                <Textarea
                    name="text"
                    maxLength={letterMaxLength}
                    minLength={10}
                    placeholder={trls[TrlKeys.placeholder]}
                    defaultValue={initialText}
                    rows={6}
                />
                <input type="hidden" name="topicId" value={topicId} />
                <div>
                    <VSpacing base={2} />
                    <FormError show={!!errorCode} data-qa="vacancy-response-error">
                        {trls[TrlKeys.errors[errorCode || ErrorType.Unknown]]}
                    </FormError>
                    <Button
                        kind={ButtonKind.Primary}
                        loading={isLoading && <Loading initial={LoadingColor.White} scale={LoadingScale.Small} />}
                        type={ButtonType.Submit}
                        disabled={isLoading}
                        stretched={submitButtonStretched}
                        data-qa="vacancy-response-letter-submit"
                    >
                        {trls[TrlKeys.submit]}
                    </Button>
                </div>
            </Form>
        </>
    );
};

export default translation(CoverLetterForm);
