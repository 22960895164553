import { ReactNode, useContext } from 'react';

import { AsyncHighlighterContext } from 'lux/components/Markup/AsyncHighlighterModule';
import HighlightProcessor from 'lux/components/Markup/HighlightProcessor';
import { NextFunction } from 'lux/components/Markup/makrupTypes';

const HighlightedString = ({ next, string, short }: { next: NextFunction; string: string; short?: boolean }) => {
    const { useHighlighted } = useContext(AsyncHighlighterContext);
    return HighlightProcessor(next, short)(useHighlighted(string));
};

export const ASYNC_HIGHLIGHT =
    (next: NextFunction) =>
    (string: string): ReactNode => {
        return string.trim() ? <HighlightedString key={string} next={next} string={string} /> : next(string);
    };

export const ASYNC_HIGHLIGHT_SHORT =
    (next: NextFunction) =>
    (string: string): ReactNode => {
        return string.trim() ? <HighlightedString key={string} next={next} string={string} short /> : next(string);
    };
