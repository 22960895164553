import classnames from 'classnames';

import {
    Card as MagritteCard,
    Text as MagritteText,
    ProgressBar as MagritteProgressBar,
    VSpacing as MagritteVSpacing,
} from '@hh.ru/magritte-ui';
import { H3Section } from 'bloko/blocks/header';
import Text from 'bloko/blocks/text';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { format } from 'bloko/common/trl';

import LoopCounterParser, { LoopCounterStage } from 'Modules/LoopCounterParser';
import Strings from 'Utils/Strings';
import LoopCounter from 'lux/components/Applicant/LoopCounter';
import translation from 'lux/components/translation';
import { useSelector } from 'lux/modules/useSelector';

import styles from './response-counter.less';

const TrlKeys = {
    pluralizeCount: 'vacancy.counter.responses.pluralizeCount',
    [LoopCounterStage.Success]: {
        title: 'vacancy.counter.responses.title.success',
        description: 'vacancy.counter.responses.description.success',
    },
    [LoopCounterStage.Progress]: {
        title: 'vacancy.counter.responses.title.progress',
        description: 'vacancy.counter.responses.description.progress',
    },
};

interface VacancyBodyResponseCounterProps {
    vacancyId: number;
    isMagritte?: boolean;
}

const VacancyBodyResponseCounter: TranslatedComponent<VacancyBodyResponseCounterProps> = ({
    vacancyId,
    isMagritte,
    trls,
}) => {
    const { responsesCount, responsesRequired } = useSelector((state) => state.applicantResponseStreaks[vacancyId]);
    const { roundCounter, completeStepCounter, stage } = LoopCounterParser(responsesRequired, responsesCount);
    const words = trls[TrlKeys.pluralizeCount].split(',');
    const titleNumber =
        stage === LoopCounterStage.Success && roundCounter > 1
            ? responsesCount
            : responsesRequired - completeStepCounter;
    const titleTrl = format(trls[TrlKeys[stage].title], {
        '{0}': `${Strings.numConversion(titleNumber, words)}`,
    });
    const descriptionTrl = trls[TrlKeys[stage].description];

    if (isMagritte) {
        return (
            <MagritteCard stretched showBorder padding={24} borderRadius={24} data-qa="responses-streak">
                <div className={styles.cardContent}>
                    <div className={styles.cardMain}>
                        <div className={styles.cardFire}>
                            <div className={styles.fire}></div>
                        </div>
                        <div className={styles.cardTexts}>
                            <MagritteText typography="subtitle-1-semibold">{titleTrl}</MagritteText>
                            <MagritteVSpacing default={4} />
                            <MagritteText typography="label-4-regular" style="secondary">
                                {descriptionTrl}
                            </MagritteText>
                        </div>
                    </div>
                </div>
                <MagritteVSpacing default={16} />
                <MagritteProgressBar max={responsesRequired} progress={completeStepCounter} />
            </MagritteCard>
        );
    }

    return (
        <div className={classnames(styles.counterWrapper, styles.counterWrapperRounded)} data-qa="responses-streak">
            <div className={styles.counter}>
                <div className={styles.counterContent}>
                    <H3Section>{titleTrl}</H3Section>
                    <VSpacing base={1} />
                    <Text>{descriptionTrl}</Text>
                </div>
                <LoopCounter
                    completeStepCounter={completeStepCounter}
                    requiredStepCounter={responsesRequired}
                    roundCounter={roundCounter}
                    stage={stage}
                    isVacancyBody
                />
            </div>
        </div>
    );
};

export default translation(VacancyBodyResponseCounter);
