import { ReactNode, ReactElement } from 'react';

import regExp from 'lux/utils/urlRegExp';

import { NextFunction } from 'lux/components/Markup/makrupTypes';
import wrapByRegExp from 'lux/components/Markup/wrapByRegExp';

const wrap = (found: RegExpExecArray): ReactElement => {
    const protocol = found[2] || 'http://';
    const www = found[3] || '';
    const link = found[4];
    return (
        <a key={found.index} href={`${protocol}${www}${link}`} rel="noopener noreferrer" target="_blank">
            {`${www || protocol}${link}`}
        </a>
    );
};

export default (next: NextFunction) =>
    (string: string): ReactNode => {
        return wrapByRegExp(string, regExp, wrap, next);
    };
