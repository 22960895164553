import { FC } from 'react';
import classnames from 'classnames';

import { useSelectorNonNullable } from '@hh.ru/redux-create-reducer';

import ChatBotButton from 'lux/components/ChatBot/Button';

import styles from './messengers.less';

interface ChatBotMessengersProps {
    column?: boolean;
    genericUrlParams?: string;
    smallButtons?: boolean;
}

const ChatBotMessengers: FC<ChatBotMessengersProps> = ({ column, smallButtons, genericUrlParams = '' }) => {
    const messengers = useSelectorNonNullable((store) => store.chatBot.messengers);

    return (
        <div className={classnames(styles.messengers, { [styles.messengersColumn]: column })}>
            {messengers.map(({ type, href }, index) => (
                <ChatBotButton
                    key={index}
                    genericUrlParams={genericUrlParams}
                    messenger={type}
                    small={smallButtons}
                    href={href}
                />
            ))}
        </div>
    );
};

export default ChatBotMessengers;
