import { FC } from 'react';
import classnames from 'classnames';

import { Text } from '@hh.ru/magritte-ui';
import MetroPin from 'bloko/blocks/metroPin';

import FakeMagrittePrimaryText from 'lux/components/VacancySearchItem/FakeMagrittePrimaryText';
import useExperiment from 'lux/hooks/useExperiment';
import useMagritte from 'lux/hooks/useMagritte';

interface MetroPointProps {
    name?: string;
    color?: string;
    small?: boolean;
    id?: number;
    isVacancyCard?: boolean;
}

const MetroPoint: FC<MetroPointProps> = ({ name, color, small, id, isVacancyCard }) => {
    const useInter = useExperiment('font-inter', true, false);
    const isMagritte = useMagritte();

    const renderPoint = () => {
        if (small) {
            return (
                <span
                    className={classnames(
                        'metro-point',
                        { 'metro-point_redesign': isVacancyCard },
                        { 'metro-point_inter': useInter || isVacancyCard }
                    )}
                    style={{ color }}
                />
            );
        }
        return <MetroPin color={color} />;
    };

    if (isMagritte) {
        return (
            <span className="metro-station" data-qa-id={id}>
                {color && renderPoint()}
                {isVacancyCard ? (
                    <Text typography="label-3-regular" Element="span">
                        {name}
                    </Text>
                ) : (
                    name
                )}
            </span>
        );
    }
    return (
        <span className="metro-station" data-qa-id={id}>
            {color && renderPoint()}
            {isVacancyCard ? <FakeMagrittePrimaryText Element="span">{name}</FakeMagrittePrimaryText> : name}
        </span>
    );
};

export default MetroPoint;
