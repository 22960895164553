import { ReactNode } from 'react';

import { NextFunction } from 'lux/components/Markup/makrupTypes';

export default (next: NextFunction) =>
    (string: string): ReactNode => {
        const lines = string.split(/\n/g);
        const result = [];
        for (let i = 0; i < lines.length; i++) {
            result.push(next(lines[i]));
            if (i < lines.length - 1) {
                result.push(<br key={i} />);
            }
        }
        return result;
    };
