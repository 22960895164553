import { useRef } from 'react';

import { TooltipHover, Text } from '@hh.ru/magritte-ui';
import HoverTip, { TipLayer, TipPlacement } from 'bloko/blocks/drop/Tip/HoverTip';
import { ItAccreditationScaleSmall, ItAccreditationScaleMedium, IconColor } from 'bloko/blocks/icon';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'lux/components/translation';
import { useSelector } from 'lux/modules/useSelector';

const TrlKeys = {
    label: 'vacancy.itAccreditation.label',
};

interface ItAccreditationProps {
    isHighlighted?: boolean;
    isInverted?: boolean;
    isMagritte?: boolean;
}

const Features = {
    enableItAccreditation: 'enable_accredited_it_employer_search',
};

const ItAccreditation: TranslatedComponent<ItAccreditationProps> = ({
    trls,
    isHighlighted,
    isInverted,
    isMagritte,
}) => {
    const activatorRef = useRef<HTMLSpanElement>(null);
    const enableItAccreditation = useSelector(({ features }) => features[Features.enableItAccreditation]);

    if (!enableItAccreditation) {
        return null;
    }

    const getIconColor = () => {
        if (isInverted) {
            return IconColor.White;
        }
        if (isHighlighted) {
            return IconColor.Gray70;
        }
        return IconColor.Gray50;
    };

    const renderIcon = () => {
        return isHighlighted ? (
            <ItAccreditationScaleMedium initial={getIconColor()} />
        ) : (
            <ItAccreditationScaleSmall initial={getIconColor()} />
        );
    };

    return (
        <span className="serp-item-link-disable">
            {isMagritte ? (
                <>
                    <span ref={activatorRef}>{renderIcon()}</span>
                    <TooltipHover
                        arrowNavigation
                        direction="top"
                        alignment="center"
                        maxWidth={320}
                        activatorRef={activatorRef}
                    >
                        <Text>{trls[TrlKeys.label]}</Text>
                    </TooltipHover>
                </>
            ) : (
                <HoverTip
                    render={() => trls[TrlKeys.label]}
                    placement={TipPlacement.Top}
                    layer={TipLayer.Overlay}
                    host={!process.env.LUX_SERVER ? document.body : null}
                >
                    {renderIcon()}
                </HoverTip>
            )}
        </span>
    );
};

export default translation(ItAccreditation);
