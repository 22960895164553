import classnames from 'classnames';

import { H3Section } from 'bloko/blocks/header';
import Text from 'bloko/blocks/text';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import ChatBotMessengers from 'lux/components/ChatBot/Messengers';
import translation from 'lux/components/translation';

import styles from './response-chat-bot.less';

const TrlKeys = {
    title: 'response.chatBot.title',
    description: 'response.chatBot.description',
};

interface ResponseChatBotProps {
    usedResumeHash?: string;
}

const ResponseChatBot: TranslatedComponent<ResponseChatBotProps> = ({ trls, usedResumeHash }) => (
    <>
        <VSpacing base={4} />
        <div className={classnames(styles.chatBot, styles.chatBotRounded)}>
            <div className={styles.chatBotWrapper}>
                <H3Section>{trls[TrlKeys.title]}</H3Section>
                <VSpacing base={1} />
                <Text>{trls[TrlKeys.description]}</Text>
                <VSpacing base={1} />
                <ChatBotMessengers genericUrlParams={usedResumeHash} smallButtons />
            </div>
        </div>
    </>
);

export default translation(ResponseChatBot);
