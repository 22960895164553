import { forwardRef } from 'react';
import classnames from 'classnames';

import CircleSvg from 'lux/components/Applicant/LoopCounter/CircleSVG';

import styles from './loop-counter.less';

interface StyledCircleProps {
    offset: number;
    progress: string;
    isMagritteExp?: boolean;
    isSmall?: boolean;
}

const StyledCircle = forwardRef<HTMLDivElement, StyledCircleProps>(
    ({ offset, progress, isMagritteExp, isSmall }, ref) => (
        <div
            className={classnames(styles.counterCircle, {
                [styles.counterCircleMagritte]: isMagritteExp,
                [styles.counterCircleMagritteSmall]: isSmall,
            })}
            ref={ref}
            data-progress={progress}
        >
            <CircleSvg offset={offset} isMagritteExp={isMagritteExp} />
        </div>
    )
);

export default StyledCircle;
