import { ComponentPropsWithoutRef, forwardRef } from 'react';

import { useCdnImageHost } from 'lux/hooks/useCdnImageHost';

interface CdnImgProps extends ComponentPropsWithoutRef<'img'> {
    path: string;
}

const CdnImg = forwardRef<HTMLImageElement, CdnImgProps>(({ path, ...imgProps }, ref) => {
    const fullPath = useCdnImageHost(path);
    return <img src={fullPath || ''} {...imgProps} ref={ref} />;
});

export default CdnImg;
