import { useEffect, useState } from 'react';
import { AxiosError } from 'axios';
import { CONFLICT } from 'http-status-codes';

import { useNotification } from 'lux/components/Notifications/Provider';
import {
    vacancyBlacklistEmployerLimitError,
    vacancyBlacklistVacancyLimitError,
} from 'lux/components/Notifications/VacancyView';
import defaultRequestErrorHandler from 'lux/requests/notifications/defaultRequestErrorHandler';

import {
    addEmployerRequest,
    addVacancyRequest,
    fetchVacancyBlacklistState,
    removeEmployerRequest,
    removeVacancyRequest,
    VacancyBlacklistState,
} from 'lux/components/VacancyView/blacklistActions';

export enum BlacklistStatus {
    BlacklistEmployer = 'BlacklistEmployer',
    BlacklistVacancy = 'BlacklistVacancy',
    Empty = 'EMPTY',
}

const getInitialStatus = (vacancyBlacklistStatus: VacancyBlacklistState) => {
    if (vacancyBlacklistStatus.employerIsBlacklisted) {
        return BlacklistStatus.BlacklistEmployer;
    }
    if (vacancyBlacklistStatus.vacancyIsBlacklisted) {
        return BlacklistStatus.BlacklistVacancy;
    }
    return BlacklistStatus.Empty;
};

type Params = {
    checkInitialStatus?: boolean;
    vacancyId?: number;
    employerId?: number;
};

type ActionType = () => Promise<unknown> | false;

type ReturnType = {
    addVacancy: ActionType;
    addEmployer: ActionType;
    onButtonClick: () => void;
    setIsMenuVisible: (b: boolean) => void;
    makeAction: (f: ActionType) => void;
    isLoading: boolean;
    isMenuVisible: boolean;
    status: BlacklistStatus;
};

export const useBlacklistMenu = ({ checkInitialStatus, vacancyId, employerId }: Params): ReturnType => {
    const [isLoading, setIsLoading] = useState(false);
    const [status, setStatus] = useState(BlacklistStatus.Empty);
    const [isMenuVisible, setIsMenuVisible] = useState(false);
    const { addNotification } = useNotification();

    useEffect(() => {
        if (checkInitialStatus && vacancyId) {
            fetchVacancyBlacklistState(vacancyId, employerId)
                .then((data) => {
                    setStatus(getInitialStatus(data));
                    setIsLoading(false);
                })
                .catch(console.error);
        }
    }, [checkInitialStatus, employerId, vacancyId]);
    const makeAction = (action: ActionType) => {
        setIsMenuVisible(false);
        setIsLoading(true);
        const promise = action();
        if (!promise) {
            return;
        }
        promise
            .then(() => {
                setIsLoading(false);
            })
            .catch((error) => {
                defaultRequestErrorHandler(error, addNotification);
            });
    };
    const checkReachedLimit = (error: AxiosError, status: BlacklistStatus) => {
        if (error.response?.status === CONFLICT) {
            if (status === BlacklistStatus.BlacklistVacancy) {
                return addNotification(vacancyBlacklistVacancyLimitError);
            }
            if (status === BlacklistStatus.BlacklistEmployer) {
                return addNotification(vacancyBlacklistEmployerLimitError);
            }
        }
        return Promise.reject(error);
    };
    const addVacancy = () =>
        !!vacancyId &&
        addVacancyRequest(vacancyId).then(
            () => setStatus(BlacklistStatus.BlacklistVacancy),
            (error: AxiosError) => checkReachedLimit(error, BlacklistStatus.BlacklistVacancy)
        );
    const addEmployer = () =>
        !!employerId &&
        addEmployerRequest(employerId).then(
            () => setStatus(BlacklistStatus.BlacklistEmployer),
            (error: AxiosError) => checkReachedLimit(error, BlacklistStatus.BlacklistEmployer)
        );
    const removeVacancy = () =>
        !!vacancyId && removeVacancyRequest(vacancyId).then(() => setStatus(BlacklistStatus.Empty));
    const removeEmployer = () =>
        !!employerId && removeEmployerRequest(employerId).then(() => setStatus(BlacklistStatus.Empty));
    const onButtonClick = () => {
        if (status === BlacklistStatus.BlacklistVacancy) {
            makeAction(removeVacancy);
            return;
        }
        if (status === BlacklistStatus.BlacklistEmployer) {
            makeAction(removeEmployer);
            return;
        }
        setIsMenuVisible(!isMenuVisible);
    };

    return {
        addVacancy,
        addEmployer,
        onButtonClick,
        setIsMenuVisible,
        makeAction,
        isLoading,
        isMenuVisible,
        status,
    };
};
